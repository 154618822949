import React, { useEffect, useState } from "react";
import Axios from "axios";
import ServiceConfig from "../services/Config";

export function RouteGroups({ children }) {
  const [routeGroup, setRouteGroup] = useState(null);

  useEffect(() => {
    if (
      window.routeGroups &&
      typeof window.routeGroups === "string"
    ) {
      const routeGroups = JSON.parse(window.routeGroups);
      const routeGroup =
        routeGroups && routeGroups.length > 0 ? routeGroups[0] : null;
      setRouteGroup(routeGroup);
    } else {
      // load the routes
      Axios.get(
        `${ServiceConfig.UmbracoApiBaseUrl}/routing/get`
      ).then((response) => {
        const routeGroup =
          response.data && response.data.length > 0
            ? response.data[0]
            : null;
        setRouteGroup(routeGroup);
      });
    }
  }, []);

  if (!routeGroup) {
    return <></>;
  }

  window.routeGroup = routeGroup;

  return children;
}
