import axios from "axios";
import ServiceConfig from "../Config";
import { TrackingEvent } from "../../tracking/Tracking";
import { setError } from "../ErrorService";
import RoutingService from "../RoutingService";
const servicePath = "/services/";

export default class Green4Service {
  constructor(serviceName, baseURL = null) {
    this.serviceName = serviceName;

    this.client = axios.create({
      baseURL:
        baseURL ??
        ServiceConfig.ServiceHost +
          servicePath +
          this.serviceName +
          ".svc/",
      withCredentials: true,
    });

    this.client.interceptors.request.use(
      (request) => {
        const organisationName = RoutingService.getOrganisationName();
        if (organisationName) {
          request.headers["OrganisationName"] = organisationName;
        }
        return request;
      },
      (requestConfig) => requestConfig,
      (requestError) => {
        // TODO - workout when this might fire and add tracking
        return Promise.reject(requestError);
      }
    );

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        // track error in analytics
        var event = new TrackingEvent(
          "Service Call Error",
          this.serviceName,
          error.config ? error.config.url : "",
          error.message
        );

        event.track();

        setError(null, null);

        return Promise.reject(error);
      }
    );
  }

  createDictionary(object) {
    let dictionary = [];
    for (const [key, value] of Object.entries(object)) {
      if (value != null) {
        dictionary.push({ Key: key, Value: value });
      }
    }
    return dictionary;
  }

  browserAcceptHeaders() {
    return this.client.defaults.headers.common.Accept;
  }
}
