import React, { Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./services/localizationService";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./services/AppInsights";

// components
import Spinner from "./components/elements/spinner";
import UmbracoPage from "./components/UmbracoPage";

// providers
import { RouteGroups } from "./components/RouteGroups";
import { TicketingProvider } from "./components/TicketingContext";
import { UserProvider } from "./components/UserContext";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router>
          <RouteGroups>
            <TicketingProvider>
              <UserProvider>
                <Suspense
                  fallback={
                    <div className="d-flex justify-content-center align-items-center min-vh-100">
                      <Spinner />
                    </div>
                  }
                >
                  <Route component={UmbracoPage} />
                </Suspense>
              </UserProvider>
            </TicketingProvider>
          </RouteGroups>
        </Router>
      </div>
    );
  }
}

export default withAITracking(reactPlugin, App);
