
const currentAuthority = process.env.REACT_APP_SERVER_AUTHORITY || window.location.origin;

const ServiceConfig = {
  ServiceHost: currentAuthority,
  UmbracoHost: currentAuthority,
  UmbracoApiBaseUrl: `${currentAuthority}/umbraco/api`,
  UmbracoSurfaceBaseUrl: `${currentAuthority}/umbraco/surface`,
  ImageBasePath: `${currentAuthority}/media/ImageServiceCache/`// case sensitive
}

export default ServiceConfig;
