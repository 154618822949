import Green4Service from "./ServiceClient";

class DonationManagementService extends Green4Service {
  constructor() {
    super("DonationManagementService");
  }
  createGiftAidDeclaration(startDate, endDate, currentDeclaration, historicalDeclaration, futureDeclaration, charity) {
    return this.client.post("CreateGiftAidDeclaration", {
      startDate: startDate,
      endDate: endDate,
      currentDeclaration: currentDeclaration,
      historicalDeclaration: historicalDeclaration,
      futureDeclaration: futureDeclaration,
      charity: charity
    });
  }
  getDonationsForContact() {
    return this.client.post("GetDonationsForContact");
  }
  giftAidBooking() {
    return this.client.post("GiftAidBooking");
  }
}

export default new DonationManagementService();
