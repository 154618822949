let error = null;

export function setError(title, message) {
  error = { title: title, message: message };
  executeCallbacks();
};

export function getError() {
  return error;
}

const callbacks = [];

export function registerCallback(callback) {
  callbacks.push(callback);
}

function executeCallbacks() {
  callbacks.forEach((callback) => {
    callback();
  });
}
