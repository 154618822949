const classNames = function () {
  const classes = [];

  for (let i = 0; i < arguments.length; i++) {
    const arg = arguments[i];
    if (!arg) continue;

    classes.push(arg);
  }

  return classes.join(" ");
}

export { classNames }
