import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import ServiceConfig from "./Config";

const baseUrl = ServiceConfig.UmbracoApiBaseUrl + "/localization";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    lng: "en-GB",
    fallbackLng: "en-GB",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safe from xss
    },
    load: 'currentOnly',
    saveMissing: true,
    defaultNS: 'ui',
    ns: 'ui',
    backend: {
      loadPath: `${baseUrl}/get?culture={{lng}}&ns={{ns}}`,
      addPath: `${baseUrl}/add`,
      parsePayload: (ns, key, fallback) => {
        return { ns, key, fallback }
      }
    },
    partialBundledLanguages: true
  });

export default i18n;
