import GoogleTagManager from './GoogleTagManager';
import Log from 'loglevel';

/**
 * Class representing an event to be tracked.
 */
export class TrackingEvent {
  /**
   * Create a new event to track.
   * @param {string} name - The name or type of event
   * @param {string} category - The event category
   * @param {string} action - Name of the user action that triggered the event
   * @param {string} label - 
   */
  constructor(name, category, action, label) {
    this.event = name;
    this.eventCat = category;
    this.eventAct = action;
    this.eventLbl = label;
  }
  /**
   * Track the event
   */
  track() {
    GoogleTagManager.dataLayer(this);
    Log.debug('Tracking event fired', this);
  }
}

export class EcommerceEvent {
  /**
   * Create a new Ecommerce event to track.
   * @param {string} event - The name or type of event
   * @param {object} ecommerce - The ecommerce object
   */
  constructor(event, ecommerce) {
    this.event = event;
    this.ecommerce = ecommerce;
  }

  track() {
    GoogleTagManager.dataLayer(this);
    Log.debug('Tracking Checkout event fired', this);
  }
}

export class EcommerceTransactionEvent {
  /**
   * Create a new Ecommerce Transaction event to track.
   * @param {any} event - The name or type of event
   * @param {any} ecommerce - The ecommerce object
   * @param {any} transactionId - Unique transaction identifier
   * @param {any} transactionAffiliation - Partner or store
   * @param {any} transactionTotal - Total value of the transaction
   * @param {any} transactionTax - 	Tax amount for the transaction
   * @param {any} transactionProducts - List of items purchased in the transaction
   */
  constructor(event, ecommerce, transactionId, transactionAffiliation, transactionTotal, transactionTax, transactionProducts) {
    this.event = event;
    this.ecommerce = ecommerce
    this.transactionId = transactionId;
    this.transactionAffiliation = transactionAffiliation;
    this.transactionTotal = transactionTotal;
    this.transactionTax = transactionTax;
    this.transactionProducts = transactionProducts;
  }

  track() {
    GoogleTagManager.dataLayer(this);
    Log.debug('Tracking Checkout event fired', this);
  }
}

export class DataLayer {
  /**
   * Push a key/value pair to the analytics dataLayer
   * @param {string} key 
   * @param {string} value
   */
  constructor(key, value = "") {
    this.key = key;
    this.value = value;
  }

  update() {
    const data = { [this.key]: this.value }
    GoogleTagManager.dataLayer(data);
    Log.debug('Updated google dataLayer', data);
  }
}
