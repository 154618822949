// polyfills to support older browsers
// e.g. IE 11
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
//

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./scss/main.scss";
import * as serviceWorker from "./serviceWorker";
import Log from "loglevel";
import TagManager from "./tracking/GoogleTagManager";

const Green4TagManagerID = "GTM-PB45G3V";

if (window.location !== window.parent.location) {
  // The page is in an iframe
  document.body.classList.add("bg-transparent");
} else {
  // The page is not in an iframe
}

if (process.env.NODE_ENV !== "production") {
  // default is Log.levels.WARN
  // enable all for development
  Log.enableAll();

  require("./services/AccessibilityService");

  TagManager.initialize({ gtmId: Green4TagManagerID });
} else {
  TagManager.initialize({ gtmId: window.gtmId }); // client Tag Manager
  TagManager.initialize({ gtmId: Green4TagManagerID });
}

if (window.loqateApiKey) {
  require("./address");
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
