import React, { useContext, useEffect, useRef } from "react";
import { TicketingContext } from "../TicketingContext";
import { UserContext } from "../UserContext";

function OrganisationTracker({ organisationName }) {
  const ticketingContext = useContext(TicketingContext);
  const userContext = useContext(UserContext);

  const { resetTicketingState } = ticketingContext;
  const { resetUserState } = userContext;

  const GetPreviousValue = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };

  const previousOrganisationName = GetPreviousValue(organisationName);
  const organisationHasChanged =
    previousOrganisationName &&
    previousOrganisationName !== organisationName;

  useEffect(() => {
    if (organisationHasChanged) {
      resetUserState();
      resetTicketingState();
    }
  }, [organisationHasChanged, resetTicketingState, resetUserState]);

  return <></>;
}

export default OrganisationTracker;
