import Green4Service from "./ServiceClient";

class UserService extends Green4Service {
  constructor() {
    super("UserService");
  }
  activateTemporaryAccess(accessToken, password) {
    return this.client.post("ActivateTemporaryAccess", {
      accessToken: accessToken,
      password: password,
    });
  }
  addContactAddress(
    name,
    line1,
    line2,
    city,
    county,
    postalcode,
    country
  ) {
    return this.client.post("AddContactAddress", {
      name: name,
      line1: line1,
      line2: line2,
      city: city,
      county: county,
      postalcode: postalcode,
      country: country,
    });
  }
  assignPurchaser(attributes) {
    return this.client.post("AssignPurchaser", {
      attributes: attributes,
    });
  }
  changePassword(oldPassword, newPassword) {
    return this.client.post("UserChangePassword", {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  }
  createConnection(firstName, lastName, emailAddress) {
    return this.client.post("CreateConnection", {
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
    });
  }
  getContactConnections() {
    return this.client.post("GetContactConnections");
  }
  iframeSubscribe(firstName, lastName, email) {
    return this.subscribe(firstName, lastName, email, [
      { Key: "g4_source", Value: "iframe-signup" },
    ]);
  }
  loginAndRemember(username, password, rememberMe) {
    return this.client.post("LoginAndRemember", {
      username: username,
      password: password,
      rememberMe: rememberMe,
    });
  }
  logout() {
    return this.client.post("Logout");
  }
  passwordReminder(emailAddress) {
    return this.client.post("PasswordReminder", {
      emailAddress: emailAddress,
    });
  }
  readCommunicationChannels(contactId) {
    return this.client.post("ReadCommunicationChannels", {
      contactId: contactId,
    });
  }
  readContactAddresses() {
    return this.client.post("ReadContactAddresses");
  }
  readContactSubscriptions(contactId) {
    return this.client.post("ReadContactSubscriptions", {
      contactId: contactId,
    });
  }
  register(attributes) {
    return this.updateContact(attributes, true);
  }
  registerContact(attributes, autoLogin, guestCheckout) {
    return this.client.post("RegisterContact", {
      attributes: attributes,
      autoLogin: autoLogin,
      guestCheckout: guestCheckout,
    });
  }
  removeContactAddress(addressId) {
    return this.client.post("RemoveContactAddress", {
      addressId: addressId,
    });
  }
  setContactAddressAsDefault(addressId) {
    return this.client.post("SetContactAddressAsDefault", {
      addressId: addressId,
    });
  }
  subscribe(firstName, lastName, emailAddress, attributes = null) {
    return this.client.post("Subscribe", {
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      attributes: attributes,
    });
  }
  thirdPartyLogout() {
    return this.client.post("ThirdPartyLogout");
  }
  unsubscribe(contactId, communicationSid) {
    return this.client.post("Unsubscribe", {
      contactId: contactId,
      communicationSid: communicationSid,
    });
  }
  updateContact(attributes) {
    return this.client.post("UpdateContact", {
      attributes: attributes,
    });
  }
  updateContactAddress(
    addressId,
    name,
    line1,
    line2,
    city,
    county,
    postalcode,
    country
  ) {
    return this.client.post("UpdateContactAddress", {
      addressId: addressId,
      name: name,
      line1: line1,
      line2: line2,
      city: city,
      county: county,
      postalcode: postalcode,
      country: country,
    });
  }
  updateContactSubscriptions(
    contactId,
    subscriptions,
    contactMethods,
    communicationSid,
    communicationActivityIdRef
  ) {
    return this.client.post("UpdateContactSubscriptions", {
      subscriptionsRequest: {
        ContactId: contactId,
        Subscriptions: subscriptions,
        ContactMethods: contactMethods,
        CommunicationSid: communicationSid,
        CommunicationActivityIdRef: communicationActivityIdRef,
      },
    });
  }
  updatePolicyVersion(contactId, privacyPolicyVersion) {
    return this.client.post("UpdateContactSubscriptions", {
      subscriptionsRequest: {
        ContactId: contactId,
        GDPRStatus: 1, // opted in
        PrivacyPolicyVersion: privacyPolicyVersion,
      },
    });
  }
  updateProfile(
    firstName,
    lastName,
    mobilePhone = null,
    dateOfBirth = null
  ) {
    let data = this.createDictionary({
      firstname: firstName,
      lastname: lastName,
      mobilephone: mobilePhone,
      telephone1: mobilePhone,
      birthdate: dateOfBirth,
    });
    return this.updateContact(data, false);
  }
  validateTemporaryAccessToken(token) {
    return this.client.post("ValidateTemporaryAccessToken", {
      token: token,
    });
  }
  who() {
    return this.client.post("Who");
  }
}

export default new UserService();
