class RoutingService {
  validRouteGroup = true;
  validOrganisation = true;

  // get the organisation for the configured route group
  getOrganisation() {
    // set validOrganisation by default
    this.validOrganisation = true;
    const routeGroup = window.routeGroup;
    if (
      routeGroup &&
      routeGroup.Organisations &&
      routeGroup.Organisations.length > 0
    ) {
      // if there is only one organisation, assume this the current organisation
      if (routeGroup.Organisations.length === 1) {
        return routeGroup.Organisations[0];
      } else {
        // find the organisation within window.location.pathname
        const organisationName =
          window.location.pathname.split("/")[1];
        const matchedOrganisation = routeGroup.Organisations.find(
          (organisation) =>
            organisation.Name.toLowerCase() ===
            organisationName.toLowerCase()
        );
        if (matchedOrganisation) {
          return matchedOrganisation;
        }
      }
      // this code should be unreachable unless an invalid organisation is used
      this.validOrganisation = false;
    }
    return null;
  }

  // get the name of the organisation
  getOrganisationName() {
    const organisation = this.getOrganisation();
    return organisation ? organisation.Name.toLowerCase() : "";
  }

  // get the display name of the organisation
  getOrganisationDisplayName() {
    const organisation = this.getOrganisation();
    return organisation ? organisation.DisplayName : "";
  }

  // get the  capture player names page toggle setting for the organisation
  getOrganisationCapturePlayerNamesToggle() {
    const organisation = this.getOrganisation();
    return organisation
      ? organisation.CapturePlayerNamesPageToggle
      : true;
  }

  // get the page component for the current pathname
  getPageComponent() {
    // check if the path matches the organisations path
    const organisationsPageComponent =
      this.getPageComponentByName("Organisations");
    if (
      organisationsPageComponent &&
      window.location.pathname === organisationsPageComponent.Url
    ) {
      return organisationsPageComponent;
    }

    // check if the path matches the queue path
    const queuePageComponent = this.getPageComponentByName("Queue");
    if (
      queuePageComponent &&
      window.location.pathname === queuePageComponent.Url
    ) {
      return queuePageComponent;
    }

    // don't load the component if the organisation is not valid
    if (!this.validOrganisation) return null;

    const routes = window.routeGroup.Routes;
    // check for exact match
    const route = routes.find(
      ({ Url }) =>
        window.location.pathname === this.setUrlForOrganisation(Url)
    );
    if (route) {
      return route;
    }

    // check where pathname starts with Url
    return routes.find(({ Url }) =>
      window.location.pathname
        .toLowerCase()
        .startsWith(this.setUrlForOrganisation(Url))
    );
  }

  // find the route for a page component by name
  getPageComponentByName(name) {
    const routes = window.routeGroup.Routes;
    return routes.find(({ Component }) => Component === name);
  }

  // find the url for a page component by name
  getUrlForPageComponent(name) {
    const pageComponent = this.getPageComponentByName(name);
    return pageComponent
      ? this.setUrlForOrganisation(pageComponent.Url)
      : null;
  }

  // update a given url to prepend with the organisation name
  setUrlForOrganisation(url) {
    if (!url.startsWith("/")) {
      url = `/${url}`;
    }
    const organisationName = this.getOrganisationName();
    return organisationName ? "/" + organisationName + url : url;
  }
}

export default new RoutingService();
