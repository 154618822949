import React, { Component, Suspense } from "react";
import DonationManagementService from "../services/green4/DonationManagementService";
import UserService from "../services/green4/UserService";
import RoutingService from "../services/RoutingService";

import Redirect from "../components/elements/redirect";
import { DataLayer } from "../tracking/Tracking";

// js
import {
  getHashRedirect,
  getUrlForPageComponent,
  redirectKey,
  bookingJourneyRedirectKey,
} from "../js/routing";

const PrivacyPolicyForm = React.lazy(() =>
  import("./forms/privacyPolicy")
);
const backRedirectKey = "goingBackToLoginRedirectUrl";

const ticketingEnabled = window.ticketingEnabled
  ? window.ticketingEnabled === "true"
  : false;

export const UserContext = React.createContext({
  logInUser: (callback) => {},
  logOutUser: (callback, customRedirect) => {},
  thirdPartyCancelPurchaser: (callback) => {},
  getNextPage: () => {},
  setUserContext: () => {},
});

/**
 * Simple component that checks if there is a logged in user and redirects to "/login" if not.
 * Works inside a @see UserProvider and uses @see UserContext
 */
export class RequireUser extends Component {
  static contextType = UserContext;
  render() {
    const { user, userLoaded } = this.context;
    const { bookingJourney, children } = this.props;
    // don't render anything until the user is loaded
    if (!userLoaded) {
      return <></>;
    }
    // redirect if the current user is null
    if (user === null) {
      // save the current URL so they can be redirected back after logging in.
      // if statement is here to fix bug where this render was called twice. Once when
      // on next page of booking journey before the redirect to booking-login, and then again when on
      // booking-login. Also adding check to see if the current page path isn't the same as the login
      // page path so that we don't set the login redirect to redirect to the login page
      const bookingLoginPage = getUrlForPageComponent("BookingLogin");
      const loginPage = getUrlForPageComponent("Login");
      if (
        !localStorage.getItem(redirectKey) &&
        window.location.pathname !== loginPage
      ) {
        //Set the redirect URL in the localstorage to the page we want to redirect to upon logging in.
        //Also check if the redirect URL had any URL query string search parameters so that we can
        //make sure they are included in the redirect URL
        localStorage.setItem(
          redirectKey,
          `${window.location.pathname}${
            window.location.search !== null
              ? window.location.search
              : ""
          }`
        );
      }
      return (
        <Redirect
          to={bookingJourney ? bookingLoginPage : loginPage}
        />
      );
    }

    // add user property to any children with properties
    const childrenWithProps = React.Children.map(
      children,
      (child) => {
        return React.isValidElement(child)
          ? React.cloneElement(child, { user: user })
          : child;
      }
    );

    return <>{childrenWithProps}</>;
  }
}

/**
 * Handles state management for users and login/logout functionality
 */
export class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      userLoaded: false,
      redirect: null,
      thirdPartyOperator: null,
    };
  }

  // log out the current user, and redirect to the login page
  logout = (
    EnableNewAuthenticationFlow,
    callback = null,
    customRedirect = null
  ) => {
    UserService.logout().then((response) => {
      if (callback) {
        callback();
      }

      let redirectPage = null;
      if (customRedirect) {
        redirectPage = customRedirect;
      } else {
        redirectPage = ticketingEnabled && EnableNewAuthenticationFlow
          ? getUrlForPageComponent("BookingAuthentication")
          : getUrlForPageComponent("Login");
      }

      this.setState({
        user: null,
        thirdPartyOperator: null,
        redirect: redirectPage,
      });
      localStorage.removeItem(backRedirectKey);
    });
  };

  // Remove assigned purchaser via thirdPartyLogout and redirect to given page
  thirdPartyCancelPurchaser = (redirect, callback = null) => {
    UserService.thirdPartyLogout().then((response) => {
      if (callback) {
        callback();
      }
      this.setState({
        user: null,
        redirect: RoutingService.setUrlForOrganisation(redirect),
      });
      localStorage.removeItem(backRedirectKey);
    });
  };

  // log in, by fetching the currect user from the session on the server
  login = (redirectUrl, bookingJourneyPath, callback = null) => {
    const { user } = this.state;

    if (user === null) {
      UserService.who().then((response) => {
        if (callback) {
          callback();
        }

        if (response.data !== "") {
          if (!localStorage.getItem(backRedirectKey)) {
            localStorage.setItem(
              backRedirectKey,
              localStorage.getItem(redirectKey)
            );
          }

          let userState = {
            user: response.data,
            userLoaded: true,
          };
          let redirect =
            localStorage.getItem(redirectKey) || redirectUrl || null;

          // Check if the user is on the booking journey so that we can redirect to the next page in the journey given by bookingJourneyPath
          if (redirect === "BookingJourney") {
            const redirectBookingJourney = async (
              bookingJourneyPath
            ) => {
              await this.bookingJourneyRedirect(
                bookingJourneyPath
              ).then((result) => {
                if (result) {
                  userState.redirect = result;
                } else {
                  userState.redirect = redirectUrl;
                }
                this.setState(userState);
                return result;
              });
            };
            redirectBookingJourney(bookingJourneyPath);
          } else {
            if (redirect === null) {
              const urlParams = new URLSearchParams(
                window.location.search
              );
              let redirectParam = urlParams.get("redirect");
              if (redirectParam) {
                redirect = redirectParam;
              } else {
                const hashRedirect = getHashRedirect();
                if (hashRedirect) {
                  redirect = hashRedirect;
                }
              }
            }
            if (redirect) {
              userState.redirect = redirect;
            }
            this.setState(userState);
          }

          if (response.data.Id) {
            new DataLayer("&uid", response.data.Id).update();
          }
        } else {
          this.setState({ userLoaded: true });
        }
      });
    } else {
      //There is already a user logged in and another login attempt was made
      let redirect = this.getNextPage() || redirectUrl;
      if (redirect) {
        this.setState({ redirect: redirect });
      }
    }
  };

  async bookingJourneyRedirect(bookingJourneyPath) {
    // Its possible that the redirectKey is still set in local storage but given bookingJourneyPath is null. If so then use
    // value set in bookingJourneyRedirectKey if there is one. Otherwise redirect to redirectUrl
    let path = bookingJourneyPath;
    if (!path) {
      if (!localStorage.getItem(bookingJourneyRedirectKey)) {
        return null;
      }
      path = localStorage.getItem(bookingJourneyRedirectKey);
    }
    if (path && typeof path === "object") {
      //Check for whether the logged in user needs to be asked for gift aid. This is to avoid the
      //issue where the gift aid page is loaded but is blank because its not needed and then
      //redirects to next page. This will just simply redirect to next page.
      if (path.giftAid) {
        return await DonationManagementService.giftAidBooking().then(
          (response) => {
            const result = response.data;
            if (
              result &&
              result.DonationProductCount > 0 &&
              result.DeclarationRequired
            ) {
              return path.giftAid;
            } else {
              // Gift aid not required

              return path.nextPage ? path.nextPage : "";
            }
          }
        );
      } else if (path.nextPage) {
        return path.nextPage;
      }
    } else if (typeof path === "string") {
      return path;
    }
  }

  // after the state changes (i.e. after a login/logout) check for any previous redirects and clear them out.
  componentDidUpdate() {
    if (this.state.redirect) {
      this.setState({ redirect: null });
      localStorage.removeItem(redirectKey);
      localStorage.removeItem(bookingJourneyRedirectKey);
    }
  }

  getNextPage = () => {
    if (localStorage.getItem(backRedirectKey) === "null") {
      return null;
    }
    return localStorage.getItem(backRedirectKey);
  };

  //TODO: Have setUserContext take in a user object that will then set the user state variable instead
  //of having to call the who function to update it.
  setUserContext = () => {
    UserService.who().then((response) => {
      if (response.data !== "") {
        this.setState({
          user: response.data,
        });
      }
    });
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  setThirdPartyOperator = (operator) => {
    this.setState({ thirdPartyOperator: operator });
  };

  resetUserState = () => {
    this.setState({ user: null, userLoaded: false });
  };

  render() {
    const { children } = this.props;
    const { redirect, thirdPartyOperator, user, userLoaded } =
      this.state;

    // if there's a redirect in the state, do that.
    if (redirect) {
      return <Redirect to={redirect} />;
    }

    // determine if there is an updated privacy policy to be accepted
    let showPrivacyPolicy = false;
    if (user !== null && window.privacyPolicyVersion != null) {
      showPrivacyPolicy =
        window.privacyPolicyVersion !== user.privacypolicyversion;
    }

    // the values that we will be providing the UserContext on this render
    const value = {
      user: user,
      userLoaded: userLoaded,
      thirdPartyOperator: thirdPartyOperator,
      resetUserState: this.resetUserState,
      logOutUser: this.logout,
      logInUser: this.login,
      thirdPartyCancelPurchaser: this.thirdPartyCancelPurchaser,
      getNextPage: this.getNextPage,
      setUserContext: this.setUserContext,
      setUser: this.setUser,
      setThirdPartyOperator: this.setThirdPartyOperator,
      register: this.register,
    };

    return (
      <UserContext.Provider value={value}>
        {children}
        {showPrivacyPolicy && (
          <Suspense fallback={<></>}>
            <PrivacyPolicyForm ContactId={user.Id} />
          </Suspense>
        )}
      </UserContext.Provider>
    );
  }
}
