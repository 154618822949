import { lazy } from "react";

export default class PageComponent {
  constructor(
    name,
    defaultRedirect,
    loadUser,
    loadBooking,
    loadChannel
  ) {
    this.Component = lazy(() => import(`../pages/${name}`));
    this.DefaultRedirect = defaultRedirect;
    this.LoadUser = loadUser;
    this.LoadBooking = loadBooking;
    this.LoadChannel = loadChannel;
  }
}
