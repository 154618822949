import React, { useContext, useEffect } from "react";
import { TicketingContext } from "../TicketingContext";
import { UserContext } from "../UserContext";

function PageTracker({ pageComponent }) {
  const ticketingContext = useContext(TicketingContext);
  const userContext = useContext(UserContext);

  const { LoadBooking, LoadChannel, LoadUser } = pageComponent;
  const {
    bookingLoaded,
    bookingExpiryLoaded,
    channelLoaded,
    readBooking,
    readBookingExpiry,
    readChannel,
  } = ticketingContext;
  const { logInUser, userLoaded } = userContext;

  const loadUser = !userLoaded && LoadUser;
  const loadBooking = !bookingLoaded && LoadBooking;
  const loadBookingExpiry = !bookingExpiryLoaded && LoadBooking;
  const loadChannel = !channelLoaded && LoadChannel;

  useEffect(() => {
    if (loadUser) {
      logInUser();
    }
  }, [loadUser, logInUser]);

  useEffect(() => {
    if (loadBooking) {
      readBooking();
    }
  }, [loadBooking, readBooking]);

  useEffect(() => {
    if (loadBookingExpiry) {
      readBookingExpiry();
    }
  }, [loadBookingExpiry, readBookingExpiry]);

  useEffect(() => {
    if (loadChannel) {
      readChannel();
    }
  }, [loadChannel, readChannel]);

  return <></>;
}

export default PageTracker;
