import React, { Component } from "react";
import { matchPath } from "react-router-dom";
import { Helmet } from "react-helmet";
import Axios from "axios";
import ServiceConfig from "../services/Config";
import { EcommerceEvent } from "../tracking/Tracking";

// components
import { pageComponents } from "./pageComponents";

// elements
import OrganisationTracker from "./elements/organisationTracker";
import PageTracker from "./elements/pageTracker";
import Redirect from "./elements/redirect";
import Spinner from "./elements/spinner";
import ServiceError from "./elements/serviceError";

// js
import {
  isRootUrl,
  getTypeParamRedirect,
  getUrlForPageComponent,
} from "../js/routing";

// services
import RoutingService from "../services/RoutingService";

// models
import WarningMessageData from "../models/umbraco/WarningMessageData";

// endpoints
const contentEndpoint =
  ServiceConfig.UmbracoApiBaseUrl + "/content/get";

class UmbracoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // TODO: Remove isLoading. We should try to load the component while the content is being fetched
      isLoading: true,
      pageComponent: null,
      urlParams: null,
      umbracoData: {},
      redirect: null,
    };
  }

  componentDidMount() {
    this.loadPage();
  }

  componentDidUpdate(prevProps) {
    // after the state changes, check for any previous redirects and clear them out.
    if (this.state.redirect) {
      this.setState({ redirect: null });
    }
    // check for url change
    if (this.props.location !== prevProps.location) {
      this.loadPage();
    }
  }

  loadPage() {
    this.setState({ isLoading: true });
    this.setPageComponent();
  }

  setPageComponent() {
    // load the root page component if root url
    if (isRootUrl()) {
      this.setRootPageComponent();
    } else {
      // get the page component for the current pathname
      const pageComponent = RoutingService.getPageComponent();
      if (pageComponent) {
        this.loadPageComponent(pageComponent);
      } else {
        // page component could not be found for the current pathname
        this.setNotFoundPageComponent();
      }
    }
  }

  setRootPageComponent() {
    // read the URL search parameters
    const urlParams = new URLSearchParams(window.location.search);
    const typeParam = urlParams.get("t");
    if (typeParam) {
      getTypeParamRedirect(typeParam, urlParams).then((redirect) => {
        if (redirect) {
          this.setState({ redirect: redirect });
        } else {
          this.defaultRootRedirect();
        }
      });
    } else {
      this.defaultRootRedirect();
    }
  }

  //TODO: Figure out a way to determine whether to redirect to Login or Booking Authentication
  //based on the setting EnableNewAuthenticationFlow in UmbracoData
  defaultRootRedirect() {
    // fallback to login redirect
    const loginRedirect = getUrlForPageComponent("Login");
    if (loginRedirect && RoutingService.validOrganisation) {
      this.setState({ redirect: loginRedirect });
    } else {
      this.setNotFoundPageComponent();
    }
  }

  setNotFoundPageComponent() {
    const notFoundPageComponent =
      RoutingService.getPageComponentByName("NotFound");
    if (notFoundPageComponent) {
      this.loadPageComponent(notFoundPageComponent);
    } else {
      this.setState({ isLoading: false });
    }
  }

  matchPageComponent() {
    const { pageComponent } = this.state;
    if (
      pageComponent &&
      pageComponents.hasOwnProperty(pageComponent.Component)
    ) {
      return pageComponents[pageComponent.Component];
    }

    return pageComponents["NotFound"];
  }

  // load the page component
  // set in state, load the url params, load the content and fire GTM event for ecommerce checkout funnel
  loadPageComponent(pageComponent) {
    this.setState({
      pageComponent: pageComponent,
    });

    // set the urlParams
    if (pageComponent.UrlParams) {
      const path = RoutingService.setUrlForOrganisation(
        pageComponent.Path
      );
      const match = matchPath(window.location.pathname, {
        path: path,
        exact: false,
        strict: false,
      });

      if (match) {
        this.setState({ urlParams: match.params });
      }
    }

    // load the content
    Axios.get(contentEndpoint, {
      params: {
        route: pageComponent.Url,
        organisationName: window.organisationName ?? '',
        channelName: window.channelName ?? '',
        culture: this.props.culture,
      },
    }).then((response) => {
      const umbracoData = response.data;

      if (umbracoData && umbracoData.GoogleTagCheckoutStep) {
        // fire GTM event for ecommerce checkout funnel
        new EcommerceEvent("Checkout", {
          checkout: {
            actionField: {
              step: umbracoData.GoogleTagCheckoutStep,
            },
          },
        }).track();
      }

      this.setState({
        isLoading: false,
        umbracoData: umbracoData,
      });
    });
  }

  render() {
    const { location } = this.props;
    const { isLoading, redirect, umbracoData, urlParams } =
      this.state;

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    if (isLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center min-vh-100">
          <Spinner />
        </div>
      );
    }

    // TODO: Improve error message when valid route group does not exist (i.e. multiple locations but none of them match for the domain, like Woburn Abbey/Safari)
    // this message should only appear if the user has browsed to a domain that hasn't been configured correctly
    if (!RoutingService.validRouteGroup) {
      return (
        <p className="text-center">
          An error occurred while processing your request
        </p>
      );
    }

    const matchedComponent = this.matchPageComponent();
    const PageComponent = matchedComponent.Component;
    const organisationName = RoutingService.getOrganisationName();

    return (
      <>
        <Helmet>
          <title>{umbracoData.PageTitle || umbracoData.Name}</title>
          {umbracoData.MetaDescription && (
            <meta
              name="description"
              content={umbracoData.MetaDescription}
            />
          )}
        </Helmet>
        <OrganisationTracker organisationName={organisationName} />
        <PageTracker pageComponent={matchedComponent} />
        <PageComponent
          location={location}
          umbracoData={umbracoData}
          urlParams={urlParams}
        />
        <ServiceError
          warningMessageBoxValues={
            new WarningMessageData(umbracoData)
          }
        />
      </>
    );
  }
}

UmbracoPage.defaultProps = {
  culture: "en-GB",
};

export default UmbracoPage;
