import moment from 'moment';

// convert unix date from server into a date object
const parseUnixDate = (date) => `/Date(${Date.parse(date)})/`

const convertDateToUnix = (date, format = 'x') => {
  // default to milliseconds
  // change format to uppercase 'X' for seconds
  return '/Date(' + moment(date).format(format) + ')/';
}

const convertUnixDate = (date, format = 'YYYY-MM-DD') => {
  // convert received unix date string into a formatted date string
  const dateString = date.replace('/Date(', '').replace(')/', '')
  const timeZone = findTimeZoneIndex(dateString)
  let result;

  if (timeZone !== null) {
    //UTC offset string detected
    result = dateString.slice(0, timeZone)
    result = moment.unix(result / 1000);
    result = result.utc(getTimeZone(dateString))
    return result.format(format);
  }

  result = moment.unix(dateString/1000);
  return result.format(format);
}

const getTimeZone = (date) => {
  let index = findTimeZoneIndex(date)
  if (index !== null) {
    // remove timezone from end of string
    date = date.slice(index)
    date = date.slice(0, 3) + ":" + date.slice(3);
    return date
  }
  return null
}

const findTimeZoneIndex = (string, chars = ['+', '-']) => {
  // returns index of the character denoting a timezone offset
  // in future this can be done with a splice, but support is still patchy
  for (let char of chars) {
    let index = string.indexOf(char)
    if (index !== -1) return index
  }
  return null
}

const isSameDay = (date, date1) => {
  // compare two dates to see if they fall within the same calendar day
  date = moment(date).format('YYYY-MM-DD');
  date1 = moment(date1).format('YYYY-MM-DD');
  return moment(date).isSame(date1);
}


export { convertDateToUnix, convertUnixDate, parseUnixDate, getTimeZone, isSameDay }
