import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "../../js/classNames";

class Button extends React.Component {
  render() {
    const {
      backgroundColor,
      buttonWrapperClass,
      children,
      className,
      disabled,
      hidden,
      icon,
      includeWrapper,
      isLoading,
      onClick,
      text,
      type,
      underlineText,
    } = this.props;
    const textContent = underlineText ? <u>{text}</u> : <>{text}</>;
    const busyContent = (
      <>
        <span
          className="spinner-border spinner-border-sm mr-3"
          role="status"
          aria-hidden="true"
        ></span>
        <span>{textContent}</span>
      </>
    );
    const iconContent = icon ? (
      <FontAwesomeIcon className="mr-2 buttonIcon" icon={icon} />
    ) : (
      <></>
    );
    const buttonContent = (
      <button
        onClick={onClick}
        type={type}
        className={classNames(
          "btn",
          `btn-${className}`,
          hidden ? "d-none" : ""
        )}
        style={{ backgroundColor: backgroundColor }}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          busyContent
        ) : (
          <>
            {iconContent}
            {textContent}
          </>
        )}
        {children}
      </button>
    );

    if (includeWrapper) {
      return (
        <div
          className={classNames("button-wrapper", buttonWrapperClass)}
        >
          {buttonContent}
        </div>
      );
    }

    return <>{buttonContent}</>;
  }
}

Button.defaultProps = {
  type: "button",
  icon: null,
  text: "",
  className: "primary",
  backgroundColor: "",
  disabled: false,
  isLoading: false,
  hidden: false,
  underlineText: false,
  includeWrapper: true,
};

export default Button;
