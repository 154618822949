import React, { Suspense } from "react";
import { Translation } from "react-i18next";
import "./../forms.scss";

const Spinner = ({ color = "primary" }) => {
  return (
    <div className={`spinner-border text-${color}`} role="status">
      <span aria-live="polite" className="sr-only">
        <Suspense fallback="loading">
          <Translation ns="ui">
            {(t) => t("loading", "Loading...")}
          </Translation>
        </Suspense>
      </span>
    </div>
  );
};

export default Spinner;
