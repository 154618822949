import React from "react";
import { Redirect } from "react-router-dom";

class RedirectComponent extends React.Component {
  render() {
    const { to, journeyStartedOnIFrame, ...props } = this.props;
    //Return if no to value was given
    if (!to) {
      return;
    }

    //Set the parent location URL if in an iFrame
    if (
      window.location !== window.parent.location ||
      journeyStartedOnIFrame
    ) {
      if (to && to.pathname) {
        let url = new URL(window.location.origin + to.pathname);
        if (to.state && to.state.nextPage) {
          //In the event of the nextPage state being an object containing a giftAid and nextPage value,
          //then make sure both are in the query string param
          if (
            to.state.nextPage.giftAid &&
            to.state.nextPage.nextPage
          ) {
            url.searchParams.set(
              "giftAid",
              to.state.nextPage.giftAid
            );
            url.searchParams.set(
              "nextPage",
              to.state.nextPage.nextPage
            );
          } else {
            url.searchParams.set("nextPage", to.state.nextPage);
          }
        }
        window.parent.location = url;
      } else {
        window.parent.location = to;
      }
      return;
    }

    //Redirect as normal using third party
    window.scrollTo(0, 0);
    return <Redirect to={to} {...props} />;
  }
}

RedirectComponent.defaultProps = {
  journeyStartedOnIFrame: false,
};

export default RedirectComponent;
