import Green4Service from "./ServiceClient";

class TicketingService extends Green4Service {
  constructor() {
    super("TicketingService");
  }
  additionalProducts(productVariantIds) {
    return this.client.post("AdditionalProducts", {
      productVariantIds: productVariantIds,
    });
  }
  addMembership(
    membershipPaymentPlanId,
    quantity,
    membershipId,
    isGroup,
    readBooking = true
  ) {
    let data = [];
    if (readBooking) data.push("Booking");
    return this.client.post("AddMembership", {
      membershipPaymentPlanId: membershipPaymentPlanId,
      quantity: quantity,
      membershipId: membershipId,
      isGroup: isGroup,
      ticketingRequest: {
        Data: data,
      },
    });
  }
  addPlayerNameToGames(gamesAndPlayers, boostScoringAndPlayers) {
    return this.client.post("AddPlayerNameToGames", {
      gamesAndPlayers: gamesAndPlayers,
      boostScoringAndPlayers: boostScoringAndPlayers,
    });
  }
  addProducts(
    productRequests,
    attributes = [],
    readBooking = true,
    readBookingProducts = false,
    readAdditionalProducts = false
  ) {
    let data = [];
    if (readBooking) data.push("Booking");
    if (readBookingProducts) data.push("BookingProducts");
    if (readAdditionalProducts) data.push("AdditionalProducts");
    let productsToAdd = [
      {
        ProductRequests: productRequests,
        TicketingRequest: {
          Data: data,
        },
        Attributes: attributes,
      },
    ];
    return this.client.post("AddProducts", {
      productsToAdd: productsToAdd,
    });
  }
  addPromotionOrVoucherCode(code, skipPromotionCriteriaCheck) {
    return this.client.post("AddPromotionOrVoucherCode", {
      code: code,
      skipPromotionCriteriaCheck: skipPromotionCriteriaCheck,
    });
  }
  bookingSearch(pageRequested, pageSize, searchType) {
    return this.client.post("BookingSearch", {
      pageRequested: pageRequested,
      pageSize: pageSize,
      searchType: searchType,
    });
  }
  bowlingReadSessionPlayers(bookingId) {
    return this.client.post("BowlingReadSessionPlayers", {
      bookingId: bookingId,
    });
  }
  channel() {
    return this.client.post("Channel");
  }
  couponCategories() {
    return this.client.post("CouponCategories");
  }
  couponTypes(categoryId) {
    return this.client.post("CouponTypes", {
      categoryId: categoryId,
    });
  }
  createQuestionAnswers(questionAnswers) {
    return this.client.post("CreateQuestionAnswers", {
      questionAnswers: questionAnswers,
    });
  }
  findMemberships() {
    return this.client.post("FindMemberships", {});
  }
  generatePaymentSchedule(paymentMethodId) {
    return this.client.post("GeneratePaymentSchedule", {
      paymentMethodId: paymentMethodId,
    });
  }
  getAdditionalProductPageGroups(
    productId,
    start,
    productRequests,
    validateAdditionalProductAvailability
  ) {
    return this.client.post("GetAdditionalProductPageGroups", {
      productId: productId,
      start: start,
      productRequests: productRequests,
      validateAdditionalProductAvailability:
        validateAdditionalProductAvailability,
    });
  }
  getAvailableDatesForProduct(productId, from) {
    return this.client.post("GetAvailableDatesForProduct", {
      productId: productId,
      from: from,
    });
  }
  getAvailableProducts(productVariantIds, start, bookingProductIds) {
    return this.client.post("GetAvailableProducts", {
      productVariantIds: productVariantIds,
      start: start,
      bookingProductIds: bookingProductIds,
    });
  }
  getBowlingSessions(
    productRequests,
    productId,
    from,
    periods,
    exclusionPeriods,
    interval
  ) {
    return this.client.post("GetBowlingSessions", {
      productRequests: productRequests,
      productId: productId,
      from: from,
      periods: periods,
      exclusionPeriods: exclusionPeriods,
      interval: interval,
    });
  }
  getEvent(eventId) {
    return this.client.post("GetEvent", {
      eventId: eventId,
    });
  }
  getSeries(seriesId) {
    return this.client.post("GetSeries", {
      seriesId: seriesId,
    });
  }
  getMembershipPaymentPlansForProductVariants(
    startDateTime,
    productRequests
  ) {
    return this.client.post(
      "GetMembershipPaymentPlansForProductVariants",
      {
        startDateTime: startDateTime,
        productRequests: productRequests,
      }
    );
  }
  getNextAvailableDate(productId, productRequests) {
    return this.client.post("getNextAvailableDate", {
      productId: productId,
      productRequests: productRequests,
    });
  }
  getPaymentMandates() {
    return this.client.post("GetPaymentMandates", {});
  }
  getProductGroup(groupId) {
    return this.client.post("GetProductGroup", {
      groupId: groupId,
    });
  }
  getProductGroupByCategory(categoryId) {
    return this.client.post("GetProductGroupByCategory", {
      categoryId: categoryId,
    });
  }
  getBookingForUserAccess(bookingId, contactId) {
    return this.client.post("GetBookingForUserAccess", {
      bookingId: bookingId,
      contactId: contactId,
    });
  }
  getVehicleRegistrations(bookingId, contactId) {
    return this.client.post("GetVehicleRegistrations", {
      bookingId: bookingId,
      contactId: contactId,
    });
  }
  golfReadSessionPlayers(bookingId) {
    return this.client.post("GolfReadSessionPlayers", {
      bookingId: bookingId,
    });
  }
  initiateBookingSessionCountdown() {
    return this.client.post("InitiateBookingSessionCountdown", {});
  }
  makeDirectDebitPayment(
    paymentMethodId,
    accountName,
    accountNumber,
    bankName,
    sortCode,
    id,
    request = null
  ) {
    return this.client.post("MakeDirectDebitPayment", {
      paymentMethodId: paymentMethodId,
      accountName: accountName,
      accountNumber: accountNumber,
      bankName: bankName,
      sortCode: sortCode,
      id: id,
      request: request,
    });
  }
  makeInvoicePayment(paymentMethodId, partnerReference) {
    return this.client.post("MakeInvoicePayment", {
      paymentMethodId: paymentMethodId,
      partnerReference: partnerReference,
    });
  }
  makeZeroPayment(partnerReference, request = null) {
    return this.client.post("MakeZeroPayment", {
      partnerReference: partnerReference,
      request: request,
    });
  }
  product(productId) {
    return this.client.post("Product", {
      productId: productId,
    });
  }
  productVariants(productId) {
    return this.client.post("ProductVariants", {
      productId: productId,
    });
  }
  readBooking() {
    return this.client.post("ReadBooking", {});
  }
  readBookingByReference(reference) {
    return this.client.post("ReadBookingByReference", {
      reference: reference,
    });
  }
  readRestrictedBookingFromBookingIdAndContactId(
    bookingId,
    contactId
  ) {
    return this.client.post(
      "ReadRestrictedBookingFromBookingIdAndContactId",
      {
        bookingId: bookingId,
        contactId: contactId,
      }
    );
  }
  readBookingExpiry() {
    return this.client.post("ReadBookingExpiry", {});
  }
  readBookingQuestions() {
    return this.client.post("ReadBookingQuestions", {});
  }
  readCouponsForContact() {
    return this.client.post("ReadCouponsForContact", {});
  }
  readDeliveryProductVariants() {
    return this.client.post("ReadDeliveryProductVariants", {});
  }
  readMembershipAccount() {
    return this.client.post("ReadMembershipAccount");
  }
  readSessions(
    productRequests,
    isBundle,
    fromDate,
    interval,
    periods,
    exclusionPeriods
  ) {
    return this.client.post("ReadSessions", {
      productRequests: productRequests,
      isBundle: isBundle,
      from: fromDate,
      interval: interval,
      periods: periods,
      exclusionPeriods: exclusionPeriods,
    });
  }
  readSessionsForMonth(productRequests, from) {
    return this.client.post("ReadSessionsForMonth", {
      productRequests: productRequests,
      from: from,
    });
  }
  readTimespanelSessionsForGolf(
    productReqs,
    from,
    games,
    periods,
    exclusionPeriods
  ) {
    return this.client.post("ReadTimespanelSessionsForGolf", {
      productReqs: productReqs,
      from: from,
      games: games,
      periods: periods,
      exclusionPeriods: exclusionPeriods,
    });
  }
  removeVoucherPayment(voucherCode) {
    return this.client.post("RemoveVoucherPayment", {
      voucherCode: voucherCode,
    });
  }
  saveBowlingPlayers(gamesAndPlayers) {
    return this.client.post("SaveBowlingPlayers", {
      gamesAndPlayers: gamesAndPlayers,
    });
  }
  saveVehicleRegistrationDetails(
    bookingId,
    contactId,
    vehicleRegistrationDetails
  ) {
    return this.client.post("SaveVehicleRegistrationDetails", {
      bookingId: bookingId,
      contactId: contactId,
      vehicleRegistrationDetails: vehicleRegistrationDetails,
    });
  }
  saveVehicleRegistrationDetailsForPendingBooking(
    vehicleRegistrationDetails
  ) {
    return this.client.post("SaveVehicleRegistrationDetailsForPendingBooking", {
      vehicleRegistrationDetails: vehicleRegistrationDetails
    });
  }

  updateBookingProductQuantity(productRequests) {
    return this.client.post("UpdateBookingProductQuantity", {
      productRequests: productRequests,
    });
  }
  validateBundleDetails(productId) {
    return this.client.post("ValidateBundleDetails", {
      productId: productId,
    });
  }
  validateCouponCodes(couponTypeId, code1, code2) {
    return this.client.post("ValidateCouponCodes", {
      couponTypeId: couponTypeId,
      code1: code1,
      code2: code2,
    });
  }
  validateEmailUsername(email) {
    return this.client.post("ValidateEmailUsername", {
      email: email,
    });
  }
  validateMembershipPaymentMandate(
    accountName,
    accountNumber,
    bankName,
    sortCode,
    id
  ) {
    return this.client.post("ValidateMembershipPaymentMandate", {
      accountName: accountName,
      accountNumber: accountNumber,
      bankName: bankName,
      sortCode: sortCode,
      id: id,
    });
  }
}

export default new TicketingService();
