// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

// empty functions return if there's no instrumentation key
// prevents trying to contact app Insights without a key
// TODO: find way to avoid loading appInsights at all if no key is set
let reactPlugin = { trackMetric: () => { } }
let appInsights = () => { }

if (window.instrumentationKey) {
  const browserHistory = createBrowserHistory({ basename: '' });
  reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: window.instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory }
      }
    }
  });
  appInsights.loadAppInsights();

}

export { reactPlugin, appInsights };
