import React from "react";
import {
  getError,
  registerCallback,
} from "../../services/ErrorService";
import Button from "./button";

// components
import Modal from "./modal";

class ServiceError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }
  componentDidMount() {
    this.setError();
    registerCallback(this.setError);
  }
  clearError = () => {
    this.setState({ error: null });
  };
  setError = () => {
    this.setState({ error: getError() });
  };
  render() {
    const {
      WarningMessageBoxHeading,
      WarningMessageBoxReloadPageButtonText,
      WarningMessageBoxStartAgainButtonText,
      WarningMessageBoxStartAgainButtonUrl,
      WarningMessageBoxText,
      WarningMessageBoxOrText,
      WarningMessageBoxOrTextToggle,
    } = this.props.warningMessageBoxValues;

    if (this.state.error === null) {
      return <></>;
    }

    return (
      <Modal
        className="modal-danger"
        title={
          this.state.error.title
            ? this.state.error.title
            : WarningMessageBoxHeading
        }
        modalCloseButtonClick={() => this.clearError()}
      >
        <div className="modal-body">
          {this.state.error.message
            ? this.state.error.message
            : WarningMessageBoxText}
          <div className="justify-content-center align-items-center pb-2 pt-4 mt-1">
            <Button
              onClick={() => window.location.reload()}
              text={WarningMessageBoxReloadPageButtonText}
              className="primary"
            />
          </div>
          {WarningMessageBoxOrTextToggle && (
            <div className="d-flex justify-content-center align-items-center pb-2">
              {WarningMessageBoxOrText}
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center pb-2">
            <a
              className="btn btn-link p-0"
              href={WarningMessageBoxStartAgainButtonUrl || "#1"}
            >
              <Button
                text={WarningMessageBoxStartAgainButtonText}
                className="link"
              />
            </a>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ServiceError;
