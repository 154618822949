export class WarningMessageData {
  constructor({ WarningMessageBoxHeading
    , WarningMessageBoxReloadPageButtonText
    , WarningMessageBoxStartAgainButtonText
    , WarningMessageBoxStartAgainButtonUrl
    , WarningMessageBoxText
    , WarningMessageBoxOrText
    , WarningMessageBoxOrTextToggle
  }) {
    this.WarningMessageBoxHeading = WarningMessageBoxHeading;
    this.WarningMessageBoxReloadPageButtonText = WarningMessageBoxReloadPageButtonText;
    this.WarningMessageBoxStartAgainButtonText = WarningMessageBoxStartAgainButtonText;
    this.WarningMessageBoxStartAgainButtonUrl = WarningMessageBoxStartAgainButtonUrl;
    this.WarningMessageBoxText = WarningMessageBoxText;
    this.WarningMessageBoxOrText = WarningMessageBoxOrText;
    this.WarningMessageBoxOrTextToggle = WarningMessageBoxOrTextToggle;
  }
}

export default WarningMessageData;
