// js
import { pageComponents } from "../components/pageComponents";

// services
import RoutingService from "../services/RoutingService";
import UserService from "../services/green4/UserService";
import TicketingService from "../services/green4/TicketingService";

const redirectKey = "loginRedirectUrl";
const bookingJourneyRedirectKey = "bookingJourneyRedirect";

const isRootUrl = () => {
  const pathname = window.location.pathname.toLowerCase();
  const organisationName = RoutingService.getOrganisationName();
  if (
    pathname === "/" ||
    (organisationName && pathname === "/" + organisationName + "/")
  ) {
    return true;
  }
  return false;
};

const getUrlForPageComponent = (name) => {
  const url = RoutingService.getUrlForPageComponent(name);
  if (url) {
    return url;
  }
  return pageComponents.hasOwnProperty(name)
    ? pageComponents[name].DefaultRedirect
    : null;
};

const getTypeParamRedirect = (typeParam, urlParams) => {
  return new Promise(function (resolve) {
    switch (typeParam.toLowerCase()) {
      case "gettickets":
        getTicketsAndUserAccessRedirect(
          urlParams.get("b"),
          urlParams.get("s")
        ).then((redirect) => {
          resolve(redirect);
        });
        break;
      case "temporaryaccess":
        getTemporaryAccessRedirect(urlParams.get("k")).then(
          (redirect) => {
            resolve(redirect);
          }
        );
        break;
      case "update":
        resolve(getUrlForPageComponent("Profile"));
        break;
      case "unsubscribe":
        resolve(
          getUnsubscribeRedirect(
            urlParams.get("s"),
            urlParams.get("c")
          )
        );
        break;
      default:
        resolve(null);
        break;
    }
  });
};

const getTicketsAndUserAccessRedirect = (bookingId, contactId) => {
  return new Promise(function (resolve) {
    if (bookingId && contactId) {
      //Get the booking and check if user needs to login or set password
      TicketingService.getBookingForUserAccess(bookingId, contactId)
        .then((response) => {
          if (response && response.data && response.data.Booking) {
            const data = response.data;
            const { Id, PurchaserId, PurchaserEmail, Reference } =
              data.Booking;
            //Login is required if the booking has beneficiaries still to be assigned
            if (data.Result === 2) {
              const beneficiariesPage =
                getUrlForPageComponent("Beneficiaries") +
                "?bookingref=" +
                Reference +
                "&benRedirect=" +
                getUrlForPageComponent("Print") +
                "/" +
                Reference;
              //An access token is given if the purchaser on the booking doesn't have a password set.
              //In which case redirect to the set password page
              if (data.AccessToken) {
                const redirect =
                  getUrlForPageComponent("CreatePassword") +
                  "/" +
                  data.AccessToken +
                  "?redirect=" +
                  encodeURIComponent(beneficiariesPage);
                resolve({
                  pathname: redirect,
                });
              }
              //Or else if they do have the password set then redirect to the login page
              //which should then redirect to the beneficiaries page
              else {
                localStorage.setItem(redirectKey, beneficiariesPage);
                //TODO: Need to be able to pass through whether new booking authentication page flag from umbraco should be used
                //(as it is not known at the time this method is called)
                resolve({
                  pathname: getUrlForPageComponent("Login"),
                  state: { purchaserEmail: PurchaserEmail },
                });
              }
            }
            //If there was a duplicate username found for the purchaser email
            else if (data.Result === 3) {
              resolve({
                pathname: getUrlForPageComponent("CreatePassword"),
                state: {
                  purchaserEmail: PurchaserEmail,
                  accessResult: data.Result,
                },
              });
            }
            //Or else just redirect to the print page with loginless
            else {
              resolve({
                pathname: getPrintPageAnonymousRedirect(
                  Id,
                  PurchaserId
                ),
              });
            }
          } else {
            resolve(null);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(null);
        });
    } else {
      resolve(null);
    }
  });
};

const getTemporaryAccessRedirect = (token) => {
  return new Promise(function (resolve) {
    if (token) {
      // get url for temporary access page component
      // don't provide a default value, so that we fallback to the default root redirect if not specified
      const redirect = getUrlForPageComponent("TemporaryAccess");
      if (redirect) {
        const forgottenPasswordRedirect = getUrlForPageComponent(
          "ForgottenPassword"
        );
        // validate the token
        UserService.validateTemporaryAccessToken(token)
          .then((response) => {
            const valid = response.data;
            if (valid) {
              resolve({
                pathname: redirect.concat("/" + token),
              });
            } else {
              resolve({
                pathname: forgottenPasswordRedirect,
                state: { invalidAccessToken: true },
              });
            }
          })
          .catch((err) => {
            console.log(err);
            resolve(null);
          });
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
};

const getUnsubscribeRedirect = (
  contactIdParam,
  communicationIdParam
) => {
  if (contactIdParam !== null) {
    const redirect = getUrlForPageComponent("ManageSubscriptions");
    if (redirect) {
      let manageSubscriptionsRedirect = redirect.concat(
        "/" + contactIdParam
      );
      if (communicationIdParam) {
        return manageSubscriptionsRedirect.concat(
          "/" + communicationIdParam
        );
      }
      return manageSubscriptionsRedirect;
    }
  }
  return null;
};

const getHashRedirect = () => {
  //Example URL with hash could be https://vanillaui.azurewebsites.net/login#booking-widget/test
  //window.location.hash will return #booking-widget/test
  //We must substring to get rid of the #
  //Then we have to add a / to the front for the redirect (assuming it doesn't already have one)
  let hash = "";
  if (window.location.hash && window.location.hash.startsWith("#")) {
    hash = window.location.hash.substring(1);

    if (hash && !hash.startsWith("/")) {
      hash = "/" + hash;
    }
  }

  return hash;
};

const getBookingJourneyRedirect = (
  nextPage,
  user,
  channel,
  EnableNewAuthenticationFlow
) => {
  //If user has logged in then go to the next page.
  //Otherwise redirect to booking-login page first.
  if (user) {
    return {
      redirect: nextPage,
      nextPageComponent: null,
    };
  } else {
    localStorage.setItem(redirectKey, "BookingJourney");
    if (nextPage) {
      if (typeof nextPage === "object" && nextPage.giftAid) {
        localStorage.setItem(
          bookingJourneyRedirectKey,
          nextPage.giftAid
        );
      } else {
        localStorage.setItem(bookingJourneyRedirectKey, nextPage);
      }
    }

    return {
      redirect:
        channel && channel.ThirdParty
          ? getUrlForPageComponent("AssignPurchaser")
          : EnableNewAuthenticationFlow
          ? getUrlForPageComponent("BookingAuthentication")
          : getUrlForPageComponent("BookingLogin"),
      nextPageComponent: nextPage,
    };
  }
};

const getProgressPaymentRedirect = () => {
  // get the progress payment endpoint
  const progressPaymentUrl =
    window.location.origin + "/umbraco/surface/payment/progress";
  // append the organisationName if set
  const organisationName = RoutingService.getOrganisationName();
  return organisationName
    ? progressPaymentUrl +
        "?green4organisationname=" +
        organisationName
    : progressPaymentUrl;
};

const getPaymentErrorRedirect = () => {
  // get the payment page
  const paymentPageUrl =
    window.location.origin + getUrlForPageComponent("Payment");
  // append the paymentError parameter
  return paymentPageUrl + "?paymentError=";
};

const getNextPageFromSearchParams = () => {
  // check if nextPage has been defined in the URL parmas
  const urlParams = new URLSearchParams(window.location.search);
  const giftAidPageParam = urlParams.get("giftAid");
  const nextPageParam = urlParams.get("nextPage");
  if (giftAidPageParam) {
    return { giftAid: giftAidPageParam, nextPage: nextPageParam };
  }

  return nextPageParam ? nextPageParam : null;
};

const getPrintPageAnonymousRedirect = (bookingId, contactId) => {
  return (
    getUrlForPageComponent("Print") +
    "/?bookingid=" +
    bookingId +
    "&contactid=" +
    contactId
  );
};

const getPrintPageRedirect = (bookingReference) => {
  return getUrlForPageComponent("Print") + "/" + bookingReference;
};

const hasContentForPageComponent = (name) => {
  return RoutingService.getUrlForPageComponent(name) !== null;
};

export {
  getHashRedirect,
  getNextPageFromSearchParams,
  getPrintPageAnonymousRedirect,
  getPrintPageRedirect,
  getProgressPaymentRedirect,
  getPaymentErrorRedirect,
  getTypeParamRedirect,
  getUrlForPageComponent,
  isRootUrl,
  getBookingJourneyRedirect,
  redirectKey,
  bookingJourneyRedirectKey,
  hasContentForPageComponent,
};
